<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <main>
    <div class="w-full mb-2 fixed sticky z-50 top-0">
      <div>
        <button  v-on:click="isActive1 = !isActive1"  type="button"
                class="font-black text-left inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
          {{ select_state1 }}
          <!-- Heroicon name: solid/chevron-down -->
          <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      <!--
        Dropdown panel, show/hide based on dropdown state.

        Entering: "transition ease-out duration-100"
          From: "transform opacity-0 scale-95"
          To: "transform opacity-100 scale-100"
        Leaving: "transition ease-in duration-75"
          From: "transform opacity-100 scale-100"
          To: "transform opacity-0 scale-95"
      -->
      <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        <div class="py-1 text-center" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a @click="selectProductEvent"
             v-for="product in productList" :key="product"
             href="#" class="font-black block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
            {{ product }}
          </a>
        </div>
      </div>
    </div>
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <!-- Card內容形式             -->
    <ul role="list" class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 ">
  <!--      Content api 回傳format json例子      -->
  <!--            "record_id": 2,-->
  <!--            "time": "2022-01-17 14:37",-->
  <!--            "place": "張天賞_有機田",-->
  <!--            "operation": "整地",-->
  <!--            "detail": "曳引機",-->
  <!--            "product": "2022-C1_鹿鳴米_台南 16",-->
  <!--            "product_code": "2022-C1_鹿鳴米_張天賞",-->
  <!--            "verified": true,-->
  <!--            "error": false-->
      <li v-for="(content, index) in contents" :key="'content' + index" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="w-full flex items-center justify-between p-4 space-x-6">
          <div class="flex-1">
            <h1 class="text-right">
              <span v-if="content.verified" class="mr-2 px-4 py-0.5 text-sm leading-15 font-bold rounded-full bg-green-100 text-green-800">
                已驗證
              </span>
              <span v-if="!content.verified" class="mr-2 px-4 py-0.5 text-sm leading-15 font-bold rounded-full bg-red-100 text-red-800">
                    未驗證
              </span>
              <span v-if="content.error" class="mr-2 px-4 py-0.5 text-sm leading-15 font-bold rounded-full bg-red-100 text-red-800">
                    已註銷
              </span>
              <span v-if="!content.error" class="mr-2 px-4 py-0.5 text-sm leading-15 font-bold rounded-full bg-green-100 text-green-800">
                    未註銷
              </span>
            </h1>
            <span class="text-sm text-o_purple-200 font-black">時間:</span><br>
            <div class="pl-2 pb-1 text-o_purple-100 text-lg font-medium">{{ content.time }}</div>
            <span class="text-sm text-o_purple-200 font-black">操作類型與細節:</span><br>
            <div class="pl-2 pb-1 text-o_green text-lg font-black">{{ content.operation }}- {{ content.detail }}</div>
            <span class="text-sm text-o_purple-200 font-black">產品:</span><br>
            <div class="pl-2 pb-1 text-o_purple-100 text-lg font-medium">{{ content.product }}</div>
            <span class="text-sm text-o_purple-200 font-black">單位與施作者:</span><br>
            <div class="pl-2 pb-1 text-o_purple-100 text-lg font-medium">{{ content.product_code }}</div>
            <span class="text-sm text-o_purple-200 font-black">地點:</span><br>
            <div class="pl-2 pb-1 text-o_purple-100 text-lg font-medium">{{ content.place }}</div>
          </div>
        </div>
      </li>
    </ul>
  </main>
</template>

<script>
import Axios from 'axios'
import Config from '@/config/config'

export default {
  name: 'PlatformR',
  data: function () {
    return {
      isActive1: false,
      select_state1: '請選擇農產品',
      productList: [],
      // 用來放置product records' content
      contents: {},
      // 產品清單致能(選擇產品後才會解開)
      productListActive: false,
      isLoading: false
    }
  },
  created: function () {
    // 第一個選單farms農場選項，table格式會是第一欄是farm field第二欄是{"總季-productName":["季-農頻-productName" ...]}
    // 因此解析上要先把總季的key取出，再來iterate窮取後面的list
    this.GetProductList()
    // ------------會偵測url是否有product參數，若有自動選取，反之不做事
    const url = new URL(window.location)
    if (url.searchParams.get('product')) {
      this.selectProductEventByURL(url.searchParams.get('product'))
    }
  },
  methods: {
    // 20220211 操作紀錄頁面-產品清單取得
    // 獲得productList: 格式通常為2020-C1_Lu-Ming Rice_Tainan 16，到頁面會自動執行獲得產品清單，下拉選單用途(需要非同步處理/不用鎖畫面)
    // GET https://www.ricehouse-backend.ntu.asia/graph/product-list
    async GetProductList () {
      const productListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await productListRequest.get('/graph/product-list').then(res => {
        const resTemp = res.data
        // console.log(resTemp.productList)
        // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
        this.productList = resTemp.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 20210821 上面func可以取得product list給選單，這邊則可以從選單選出一個product (此為一個點擊事件)
    async selectProductEvent (event) {
      // 下面這個是點擊縮、放選單
      this.isActive1 = !this.isActive1
      // 選後把把選擇狀態顯示出來
      this.select_state1 = event.target.innerText
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
      // 以當前選擇的product去取得相關的record content
      await this.GetRecordContentWithoutAuthByProduct(this.select_state1)
    },
    async selectProductEventByURL (product) {
      // 下面這個是點擊縮、放選單，因為是自動看URL來選，因此不用下拉選單
      // this.isActive1 = !this.isActive1
      // 把URL取得的Product名稱設置給選擇
      this.select_state1 = product
      // 以當前選擇的product去取得相關的record content
      await this.GetRecordContentWithoutAuthByProduct(this.select_state1)
    },
    // 20220211 操作紀錄頁面-產品選擇
    // 取得record內容，不用Auth without UUID by product (需要非同步處理/要鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/service/all-records-by-product
    async GetRecordContentWithoutAuthByProduct (product) {
      this.isLoading = true
      this.productListActive = false
      // console.log('Getting record content ...')
      const dataForm = new FormData()
      dataForm.append('product', product)
      const recordContentRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await recordContentRequest.post('/service/all-records-by-product', dataForm).then(res => {
        const resTemp = res.data
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.contents = resTemp.result
          this.productListActive = true
          // checkpoint 完成要清除
          // console.log(this.contents)
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1000px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
